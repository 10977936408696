import { computed, ref } from "vue";

interface ToastOptions {
  type?: string;
  color?: any;
  description?: string;
  title?: string;
  icon?: string;
  timeout?: number;
}
export const useToasted = (options: ToastOptions) => {
  const toast = useToast();

  if (options.type == "success"){
  
    const defaultDescription = "Opération réussie.";
    const computedDescription = computed(
      () => options.description || defaultDescription
    );
    const computedIcon = computed(() => options.icon || "i-carbon-checkmark");
    const computedTitle = computed(() => options.title || "Succès");
    const computedColor = computed(() => options.color || "green");
    return createToast({
      type: "success",
      color: computedColor.value,
      description: computedDescription.value,
      icon: computedIcon.value,
      title: computedTitle.value,
      ...options,
    });
  }

 else if (options.type == "error"){
    const defaultDescription =
      "Une erreur s'est glissée, merci de contacter le support technique.";
    const computedDescription = computed(
      () => options.description || defaultDescription
    );
    const computedIcon = computed(() => options.icon || "i-carbon-clean");
    const computedTitle = computed(() => options.title || "Erreur");
    return createToast({
      type: "error",
      color: "red",
      description: computedDescription.value,
      icon: computedIcon.value,
      title: computedTitle.value,
      ...options,
    });
  }

  function createToast(options: ToastOptions) {
    const timeout = ref(options.timeout || 4000);

    return toast.add({
      id: options.type,
      timeout: timeout.value,
      icon: options.icon,
      color: options.color,
      title: options.title,
      description: options.description,
    });
  }
  return createToast;
};
